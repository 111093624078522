@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
* {
  font-family: "Lato", sans-serif;
}

.locationDetails {
  margin-top: 140px;
}
.main {
  width: 90vw !important;
}

.ui.fixed.menu {
  background-color: #738383 !important ;
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-image: linear-gradient(#3e5151, #decba4) !important;
  height: 100vh;
}
.masthead h1.ui.header {
  font-size: 4em;
}
